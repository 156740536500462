const $ = require('jquery');

window.addEventListener('DOMContentLoaded', (event) => {
    var chevrons = document.getElementById("chevron")

    if (chevrons > 0){
        chevrons.addEventListener("click", reachfirstdiv)
    };

    function reachfirstdiv() {
        document.getElementById("firstdiv").scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});

    }

});

$(document).ready(function(){

    $('#burgericon').click(function(){
        $(this).toggleClass('open');
    });
});