const $ = require('jquery');


window.addEventListener('DOMContentLoaded', (event) => {

    var menu = document.getElementById("menu");

    var menubtn = document.getElementById("js-open-menu");

    menubtn.addEventListener('click', function (){

        if (menu.classList.contains('open')){
            menu.classList.remove('open')
            menubtn.classList.remove('open')
        }else {
            menu.classList.add('open')
            menubtn.classList.add('open')
        }

    });

});


