/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */
import {onDOMContentLoaded} from "bootstrap/js/src/util";

const $ = require('jquery');

console.log("hello app.js");
// any CSS you import will output into a single css file (app.css in this case)
import './styles/app.scss';

// You can specify which plugins you need
import {Tooltip, Toast, Popover} from 'bootstrap';

import './js/firstdivreacher';
import './js/burgermenu';

import './bootstrap';


onDOMContentLoaded(function () {
    if ($(window).width() < 960) {
        if (document.getElementById("chateautoulouze") && document.getElementById("bleutoulouze") && document.getElementById("rosetoulouze")) {
            document.getElementById("chateautoulouze").style.backgroundImage = "url('/img/vins1-center_2.png')";
            document.getElementById("bleutoulouze").style.backgroundImage = "url('/img/vins2-center_2.png')";
            document.getElementById("rosetoulouze").style.backgroundImage = "url('/img/vins3-center_2.png')";

        }
    }
});


